var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":_vm.loading,"min-width":"500px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(_vm.limitFilterType === 'budget_limits')?_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-cash")]),_vm._v(_vm._s(Math.round(_vm.limitFilter_dailyLimit / 100))+" $")],1):(_vm.limitFilterType === 'clicks_limits')?_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"red--text pr-1"},[_vm._v("mdi-cursor-default-click")]),_vm._v(_vm._s(_vm.limitFilter_dailyLimit))],1):_c('span',_vm._g(_vm._b({},'span',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-icon',{staticClass:"red--text"},[_vm._v("mdi-alert-octagon-outline")])],1)]}}],null,true)},[_c('div',[(_vm.limitFilterType === 'budget_limits')?_c('span',[_vm._v("Budget by $")]):(_vm.limitFilterType === 'clicks_limits')?_c('span',[_vm._v("Budget by Clicks")]):_c('span',[_vm._v("Unlimited")])])])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-dialog',{attrs:{"max-width":"900px"},model:{value:(_vm.isVisibleValidationErrorDialog),callback:function ($$v) {_vm.isVisibleValidationErrorDialog=$$v},expression:"isVisibleValidationErrorDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"red"},[_c('span',{staticClass:"headline"},[_vm._v("API Error")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.errorMessage))])])],1)],1)],1)],1)],1),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-list',[_c('v-list-item',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v("Limit by")]),_c('v-list-item-action',[_c('v-select',{attrs:{"items":_vm.types,"hide-details":"","dense":"","solo":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.text)+" ")]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"pr-2"},[_vm._v(_vm._s(item.icon))]),_vm._v(" "+_vm._s(item.text)+" ")]}}]),model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),_c('v-list-item',[_c('v-list-item-title',{staticClass:"pr-3"},[_c('v-text-field',{attrs:{"label":"Daily limit","required":"","rules":[_vm.validateDailyLimits]},model:{value:(_vm.dailyLimits),callback:function ($$v) {_vm.dailyLimits=$$v},expression:"dailyLimits"}})],1),_c('v-list-item-title',{staticClass:"pl-3"},[_c('v-text-field',{attrs:{"rules":[_vm.validateOverallLimits],"required":"","label":"Overall limits"},model:{value:(_vm.overallLimit),callback:function ($$v) {_vm.overallLimit=$$v},expression:"overallLimit"}})],1)],1),(_vm.type === 'clicks_limits')?_c('v-list-item',[_c('v-checkbox',{attrs:{"label":"Split the budget evenly throughout the day "},model:{value:(_vm.evenly),callback:function ($$v) {_vm.evenly=$$v},expression:"evenly"}})],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","loading":_vm.loading},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green","disabled":!_vm.allowSave || _vm.loading},on:{"click":_vm.onUpdate}},[_vm._v(" Save ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }